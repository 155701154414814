import { isZero } from "./tratarInfoCarros";

function tratarDados(horas, ext) {
  try {
    // trata a informação dos horas

    let horasFormat = isZero(horas);
    if (horasFormat !== "") {
      return `${horasFormat} ${ext}`;
    } else {
      return "";
    }
  } catch (error) {
    console.log("error", error);
  }
}

export { tratarDados };
