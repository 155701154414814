import React from "react";
import css from "../carrousel.module.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation, EffectCoverflow } from "swiper";
import { ReactComponent as Arrow } from "../../../../assets/icons/arrow/Slider.svg";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/effect-coverflow";
import CardDestaque from "./CardDestaque";
import useMediaQuery from "../../../hooks/useMediaQuery ";

export default function CoverflowSliderBlur({
  ViaturasDestaques,
  infoJson,
  selectedItems,
  addToCompare,
  removeFromCompare,

  autoPlay,
}) {
  const ismobile = useMediaQuery("(max-width: 574px)");
  const responsive = {
    0: {
      slidesPerView: 1,
      spaceBetween: 10,
    },
    991: {
      slidesPerView: 3,
      spaceBetween: 20,
    },
  };

  const navigationBlurPrevRef = React.useRef(null);
  const navigationBlurNextRef = React.useRef(null);

  return (
    <Swiper
      className={"destaques-cover-flow"}
      breakpoints={responsive}
      autoplay={autoPlay ? true : false}
      pagination={
        ismobile
          ? {
              dynamicBullets: true,
            }
          : true
      }
      navigation={{
        prevEl: navigationBlurPrevRef.current,
        nextEl: navigationBlurNextRef.current,
        disabledClass: css.buttonDisable,
        lockClass: css.buttonLock,
      }}
      onBeforeInit={(swiper) => {
        swiper.params.navigation.prevEl = navigationBlurPrevRef.current;
        swiper.params.navigation.nextEl = navigationBlurNextRef.current;
      }}
      coverflowEffect={{
        rotate: -10,
        stretch: -110,
        depth: 300,
        modifier: 1,
        scale: 1,
        slideShadows: false,
      }}
      effect={"coverflow"}
      grabCursor="true"
      centeredSlides={true}
      loop={true}
      speed={500}
      modules={[Autoplay, Pagination, Navigation, EffectCoverflow]}
    >
      {ViaturasDestaques.map((carro) => {
        return (
          <SwiperSlide key={carro.CodViatura}>
            <CardDestaque
              data={carro}
              infoJson={infoJson}
              selectedItems={selectedItems}
              addToCompare={addToCompare}
              removeFromCompare={removeFromCompare}
            />
          </SwiperSlide>
        );
      })}

      <>
        <div
          className={`${css.buttonPrev} ${css.buttonPrevD}`}
          ref={navigationBlurPrevRef}
        >
          <Arrow
            style={{ transform: `rotate(180deg)` }}
            stroke={infoJson.Cores.ArrowSlider}
          />
        </div>
        <div
          className={`${css.buttonNext} ${css.buttonNextD}`}
          ref={navigationBlurNextRef}
        >
          <Arrow stroke={infoJson.Cores.ArrowSlider} />
        </div>
      </>
    </Swiper>
  );
}
