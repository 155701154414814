import React from "react";
import TopNav from "./header/TopNav";
import Footer from "./footer/Footer";
import MessengerCustomerChat from "react-messenger-customer-chat";
import CarComparison from "../../components/pages/comparador/CarComparison";
import useMediaQuery from "../../components/hooks/useMediaQuery ";
import Dot from "../../components/tools/dot/Dot";
import { Outlet } from "react-router-dom";

export default function Layout({
  infoJson,

  allinfoText,
  selectedCarsComparar,
  removeFromCompareAll,
  removeFromCompare,
  setTextoModal,
  textoModal,
  setShowModalComparison,
  showModalComparison,
}) {
  const isDesktop = useMediaQuery("(min-width: 992px)");
  return (
    <>
      <TopNav
        path={window.location.pathname}
        infoJson={infoJson}
        allinfoText={allinfoText}
      />
      <Outlet />
      <Footer infoJson={infoJson} allinfoText={allinfoText} />
      {infoJson.Stand.PluginFacebok.existe && (
        <MessengerCustomerChat
          pageId={infoJson.Stand.PluginFacebok.PageIdFacebook}
          appId={infoJson.Stand.PluginFacebok.AppIdFacebook}
          language="pt_PT"
        />
      )}
      {infoJson.Layout.Carros.Comparador && selectedCarsComparar.length > 0 && (
        <CarComparison
          infoJson={infoJson}
          selectedItems={selectedCarsComparar}
          removeFromCompare={removeFromCompare}
          show={showModalComparison}
          setShow={setShowModalComparison}
          textoModal={textoModal}
          setTextoModal={setTextoModal}
          removeFromCompareAll={removeFromCompareAll}
        />
      )}
      {isDesktop && infoJson.Layout.ContactarDot && (
        <Dot selectedItems={selectedCarsComparar} infoJson={infoJson} />
      )}
    </>
  );
}
