import React from "react";
import css from "../carrousel.module.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper";
import { ReactComponent as Arrow } from "../../../../assets/icons/arrow/Slider.svg";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import useMediaQuery from "../../../hooks/useMediaQuery ";
import CardSoloDestaque from "./CardSoloDestaque";

export default function SoloDestaque({
  ViaturasDestaques,
  infoJson,
  selectedItems,
  addToCompare,
  removeFromCompare,
  alltexto,

  autoPlay,
}) {
  const ismobile = useMediaQuery("(max-width: 574px)");
  const responsive = {
    0: {
      slidesPerView: 1,
      spaceBetween: 10,
    },
  };

  const navigationSoloPrevRef = React.useRef(null);
  const navigationSoloNextRef = React.useRef(null);

  return (
    <Swiper
      className={"destaques-cover-flow"}
      breakpoints={responsive}
      autoplay={autoPlay ? true : false}
      pagination={
        ismobile
          ? {
              dynamicBullets: true,
            }
          : true
      }
      navigation={
        ismobile
          ? false
          : {
              prevEl: navigationSoloPrevRef.current,
              nextEl: navigationSoloNextRef.current,
              disabledClass: css.buttonDisable,
              lockClass: css.buttonLock,
            }
      }
      onBeforeInit={(swiper) => {
        swiper.params.navigation.prevEl = navigationSoloPrevRef.current;
        swiper.params.navigation.nextEl = navigationSoloNextRef.current;
      }}
      effect={"slide"}
      grabCursor="true"
      centeredSlides={true}
      loop={true}
      speed={500}
      modules={[Autoplay, Pagination, Navigation]}
    >
      {ViaturasDestaques.map((carro) => {
        return (
          <SwiperSlide key={carro.CodViatura}>
            <CardSoloDestaque
              data={carro}
              infoJson={infoJson}
              selectedItems={selectedItems}
              addToCompare={addToCompare}
              removeFromCompare={removeFromCompare}
              alltexto={alltexto}
            />
          </SwiperSlide>
        );
      })}
      {ismobile ? (
        <></>
      ) : (
        <>
          <div className={`${css.buttonPrev} `} ref={navigationSoloPrevRef}>
            <Arrow
              style={{ transform: `rotate(180deg)` }}
              stroke={infoJson.Cores.ArrowSlider}
            />
          </div>
          <div className={`${css.buttonNext} `} ref={navigationSoloNextRef}>
            <Arrow stroke={infoJson.Cores.ArrowSlider} />
          </div>
        </>
      )}
    </Swiper>
  );
}
