import React from "react";
import { Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { LimparToUrl } from "../../../helpers/tratarInfoCarros";
import { selectImage, selectImageBarcos } from "./listaMarcas";
import css from "./marcas.module.css";

export default function CardMarca({ data, infoJson }) {
  const location = `${
    infoJson.Layout.Carros.existe ? "/usadas/" : "/viaturas/usadas/"
  }${LimparToUrl(data.Marca.Nome)}/${
    infoJson.Layout.Carros.existe ? "#listagem_viaturas" : ""
  }`;

  return (
    <Col xs={12}>
      <Link to={location}>
        <Col className={`listagem_info  ${css.retangle}`}>
          {data.Viatura === "barcos"
            ? selectImageBarcos(data.Marca.Id, data.Marca.Nome, infoJson)
            : selectImage(data.Marca.Id, data.Marca.Nome, infoJson)}
        </Col>
      </Link>
    </Col>
  );
}
