import React, { createRef, useEffect, useState } from "react";
import CardDestaque from "./CardDestaque";
import css from "./prism.module.css";
import { ReactComponent as Arrow } from "../../../../assets/icons/arrow/Slider.svg";

export default function Prism({
  ViaturasDestaques,
  infoJson,
  selectedItems,
  addToCompare,
  removeFromCompare,
  
}) {


  const carousel = createRef();
  const [selectedIndex, setselectedIndex] = useState(0);
  const [theta, setTheta] = useState(0);
  const [radius, setRadius] = useState(0);

  useEffect(() => {
    const rotateCarousel = () => {
      const angle = theta * selectedIndex * -1;
      carousel.current.style.transform = `translateZ(-${radius}px) rotateY(${angle}deg)`;
    };

    function changeCarousel() {
      const cellCount = carousel.current.children.length;

      const thetaIni = 360 / cellCount;
      setTheta(thetaIni);
      const cellSize = carousel.current.offsetWidth;

      const radiusInit = Math.round(
        cellSize / 2 / Math.tan(Math.PI / cellCount)
      );
      setRadius(radiusInit);

      for (var i = 0; i < carousel.current.children.length; i++) {
        var cell = carousel.current.children[i];
        var cellAngle = thetaIni * i;
        cell.style.transform = `rotateY(${cellAngle}deg) translateZ(${radiusInit}px)`;
        cell.classList.add(`prism${i}`);
      }

      rotateCarousel();
    }
    changeCarousel();
  }, [carousel, selectedIndex, theta, radius]);

  const rotateCarousel = () => {
    const angle = theta * selectedIndex * -1;
    carousel.current.style.transform = `translateZ(-${radius}px) rotateY(${angle}deg)`;
  };

  const prevButton = () => {
    setselectedIndex(selectedIndex - 1);
    rotateCarousel();
  };

  const nextButton = () => {
    setselectedIndex(selectedIndex + 1);
    rotateCarousel();
  };

  return (
    <>
      <div className={`${css.scene}`}>
        <div className={`${css.carousel_prims}`} ref={carousel}>
          {ViaturasDestaques.map((carro) => {
            return (
              <div
                key={carro.CodViatura}
                className={`${css.carousel__cell} carousel__cell`}
              >
                <CardDestaque
                  data={carro}
                  infoJson={infoJson}
                  selectedItems={selectedItems}
                  addToCompare={addToCompare}
                  removeFromCompare={removeFromCompare}
             
                />
              </div>
            );
          })}
        </div>
      </div>

      <div
        className={`${css.buttonPrev} ${css.buttonPrevD}`}
        onClick={prevButton}
      >
        <Arrow
          style={{ transform: `rotate(180deg)` }}
          stroke={infoJson.Cores.ArrowSlider}
        />
      </div>
      <div
        className={`${css.buttonNext} ${css.buttonNextD}`}
        onClick={nextButton}
      >
        <Arrow stroke={infoJson.Cores.ArrowSlider} />
      </div>
    </>
  );
}
