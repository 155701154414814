import React from "react";
import { Col } from "react-bootstrap";
import css from "./tag.module.css";
export default function Tag({
  vendido,
  reservado,
  tagImg,
  precoPromo,
  dispoBreve,
  ivaDedutivel,
  ivaDescriminado,
  infoJson,
}) {
  const renderClass = (param) => {
    switch (param) {
      case 1:
        return ` ${css.cantoSuperiorImagem}`;
      case 2:
        return ` ${css.cantoSuperiorImagemTopo}`;
      case 3:
        return ` ${css.cantoSuperiorImagemConer}`;
      case 4:
        return ` ${css.cantoSuperiorImagemBottom}`;
      default:
        return ` ${css.cantoSuperiorImagem}`;
    }
  };

  const renderClass2 = () => {
    let classN;
    vendido === true
      ? (classN = ` ${css.ConerbadgeV}`)
      : reservado === true
      ? (classN = ` ${css.ConerbadgeR}`)
      : tagImg !== ""
      ? (classN = ` Conerbadged`)
      : precoPromo !== ""
      ? (classN = ` ${css.ConerbadgeP} `)
      : dispoBreve === true
      ? (classN = ` Conerbadged`)
      : ivaDedutivel === true
      ? (classN = ` Conerbadged`)
      : ivaDescriminado === true
      ? (classN = ` Conerbadged`)
      : (classN = ` `);

    return classN;
  };

  return (
    <>
      {vendido === true ? (
        <>
          <Col className={` ${renderClass(infoJson.Layout.Carros.tagTipo)}  `}>
            <Col className={` ${renderClass2()}  `}>
              <label className={`${css.badge} ${css.badgeV}`}>Vendida</label>{" "}
            </Col>
          </Col>
        </>
      ) : reservado === true ? (
        <Col className={` ${renderClass(infoJson.Layout.Carros.tagTipo)}  `}>
          <Col className={` ${renderClass2()}  `}>
            <label className={`${css.badge} ${css.badgeR}`}>Reservado</label>{" "}
          </Col>
        </Col>
      ) : tagImg !== "" ? (
        <Col className={` ${renderClass(infoJson.Layout.Carros.tagTipo)}  `}>
          <Col className={` ${renderClass2()}  `}>
            <label className={`${css.badge} badged`}>{tagImg}</label>{" "}
          </Col>
        </Col>
      ) : precoPromo !== "" ? (
        <Col className={` ${renderClass(infoJson.Layout.Carros.tagTipo)}  `}>
          <Col className={` ${renderClass2()}  `}>
            <label className={`${css.badge} ${css.badgeP} `}>PROMOÇÃO</label>{" "}
          </Col>
        </Col>
      ) : dispoBreve === true ? (
        <Col className={` ${renderClass(infoJson.Layout.Carros.tagTipo)}  `}>
          <Col className={` ${renderClass2()}  `}>
            <label className={`${css.badge} badged`}>BREVEMENTE</label>{" "}
          </Col>
        </Col>
      ) : ivaDedutivel === true ? (
        <Col className={` ${renderClass(infoJson.Layout.Carros.tagTipo)}  `}>
          <Col className={` ${renderClass2()}  `}>
            <label className={`${css.badge} badged`}>IVA DEDUTIVEL</label>{" "}
          </Col>
        </Col>
      ) : (
        ivaDescriminado === true && (
          <Col className={` ${renderClass(infoJson.Layout.Carros.tagTipo)}  `}>
            <Col className={` ${renderClass2()}  `}>
              <label className={`${css.badge} badged`}>IVA DISCRIMINADO</label>{" "}
            </Col>
          </Col>
        )
      )}
    </>
  );
}
