import React, { useContext, useEffect, useState } from "react";
import css from "../listagem/carro.module.css";
import cssD from "./destaques.module.css";
import {
  tratarAnoMes,
  tratarCombustivel,
  tratarKm,
  tratarPreco,
  tratarPrecoPromo,
  tratarValorMensalidade,
  tratarLink,
  SplitValue,
  tratarMotorizacao,
  tratarPotencia,
} from "../../../helpers/tratarInfoCarros";
import { Col, Row } from "react-bootstrap";
import Spacer from "../Spacer";
import Tag from "../listagem/Tag";
import { Link } from "react-router-dom";
import { ReactComponent as Mais } from "../../../../assets/icons/arrow/opcoes.svg";
import { ReactComponent as Comb } from "../../../../assets/icons/caract/comb.svg";
import { ReactComponent as Calendar } from "../../../../assets/icons/caract/calendar.svg";
import { ReactComponent as Km } from "../../../../assets/icons/caract/km.svg";
import { ReactComponent as Fechar } from "../../../../assets/icons/modal/fechar.svg";
import { ReactComponent as Potencia } from "../../../../assets/icons/caract/potencia.svg";
import cssO from "../listagem/opcoes.module.css";
import { CSSTransition } from "react-transition-group";
import OpcoesCarro from "../listagem/OpcoesCarro";
import MobileDetect from "mobile-detect";
import { yScrollContext } from "../../../tools/restoreScroll/yScroll";
import { Picture } from "react-responsive-picture";

export default function CardSoloDestaque({
  data,
  infoJson,
  selectedItems,
  addToCompare,
  removeFromCompare,
}) {
  const { yScroll } = useContext(yScrollContext);
  const [opcoes, setOpcoes] = useState(false);
  const [imagem, setImagem] = useState("");
  const [srcSet, setSrcSet] = useState("");
  const [exit, setExit] = useState(true);

  useEffect(() => {
    if (data.Ficheiros.length !== 0) {
      const ImagemPrincipal = data.Ficheiros.filter((carro) => {
        return carro.Principal === true;
      });

      setImagem(ImagemPrincipal[0]);
    }
  }, [data]);

  useEffect(() => {
    if (imagem.Ficheiro) {
      const imagemSeloGrande = imagem.Ficheiro;
      const imagemSeloMedio = imagem.Ficheiro.replace("-6", "-4");
      const imagemSeloPequeno = imagem.Ficheiro.replace("-6", "-2");

      const imageVariants = [
        {
          srcSet: imagemSeloPequeno,
          media: "(max-width: 350px)",
        },
        {
          srcSet: imagemSeloMedio,
          media: "(max-width:991px)",
        },
        {
          srcSet: imagemSeloGrande,
        },
      ];
      setSrcSet(imageVariants);
    }
  }, [imagem]);
  const nodeRef = React.useRef(null);
  const CombustivelTxt = tratarCombustivel(data.Combustivel.Nome);
  const location = tratarLink(
    data.Marca.Nome,
    data.Viatura === "barcos" ? data.Motorizacao : data.Modelo.Nome,
    data.CodViatura,
    data.Viatura
  );
  const ano = tratarAnoMes(data.Ano, data.Mes);
  const km = tratarKm(data.Km);
  const PrecoLimpo = tratarPreco(data.Preco);
  const PrecoPromoLimpo = tratarPrecoPromo(data.PrecoPromo);
  const ValorMensalidadeLimpo = tratarValorMensalidade(data.ValorMensalidade);
  const Motorizacao = tratarMotorizacao(
    data.Viatura === "barcos"
    ? data.Motorizacao
    : data.VersaoAlternatica,
    data.Motorizacao
  );
  const textPotencia = tratarPotencia(data.Potencia, data.Viatura);
  const PrecoArray = SplitValue(PrecoLimpo);
  const PrecoPromoArray = SplitValue(PrecoPromoLimpo);
  let userAgent;
  let deviceType;
  userAgent = navigator.userAgent;
  const md = new MobileDetect(userAgent);
  if (md.tablet()) {
    deviceType = "tablet";
  } else if (md.mobile()) {
    deviceType = "mobile";
  } else {
    deviceType = "desktop";
  }

  return (
    <>
      <Col
        xs={12}
        style={{ marginTop: "20px" }}
        className={`${cssD.boxSolo} ${cssD.box}`}
      >
        <Row xs={1} lg={2} className="align-items-center">
          <Col xs={12} lg={7} className={`  ${cssD.ImagemDestaque}`}>
            {infoJson.Layout.Carros.tagTipo !== 4 && (
              <Tag
                vendido={data.Vendido}
                reservado={data.Reservado}
                tagImg={data.TagImg}
                precoPromo={data.PrecoPromo}
                dispoBreve={data.DisponivelBrevemente}
                ivaDedutivel={data.PrecoIvaDedutivel}
                ivaDescriminado={data.IvaDiscriminado}
                infoJson={infoJson}
              />
            )}
            <Link
              to={location}
              state={{
                backward: {
                  yscroll: yScroll,
                },
              }}
            >
              <div className={`${cssD.Imagem} ${css.imgbEqual}`}>
                {srcSet !== "" && (
                  <Picture
                    alt={`${data.Marca.Nome} ${data.Modelo.Nome}`}
                    sources={srcSet}
                  />
                )}
              </div>
            </Link>
          </Col>

          <Col
            xs={12}
            lg={5}
            onMouseLeave={() => {
              exit && setOpcoes(false);
            }}
            className={`   listagem_info ${cssD.listagem_solo_destaques} ${
              infoJson.Layout.Carros.Comparador &&
              ` ${
                selectedItems &&
                selectedItems.filter((e) => e.CodViatura === data.CodViatura)
                  .length > 0
                  ? " listagem_info_comparar "
                  : " "
              } `
            } `}
          >
            <Link
              to={location}
              state={{
                backward: {
                  yscroll: yScroll,
                },
              }}
            >
              <Spacer clasName="col-12 col nopadding" height="30px" />
              <Col xs={12} className={cssD.texto_listagem_marca_solo}>
                {data.Marca.Nome}
              </Col>
              <Col xs={12} className={cssD.texto_listagem_modelo_solo}>
                {data.Modelo.Nome} {Motorizacao}
              </Col>
              <Col xs={12} className={css.dCart1}>
                <div className={cssD.Row}>
                  <div className={cssD.listagem_specs_item_solo}>
                    <div>
                      <Calendar
                        height="27.2px"
                        width="27.2px"
                        stroke={infoJson.Cores.Principal}
                      />
                    </div>
                    <div className={cssD.listagem_specs_info_solo}>
                      <div>Registo</div>
                      <div> {ano}</div>
                    </div>
                  </div>
                  {data.Viatura === "motas" ? (
                    <div className={cssD.listagem_specs_item_solo}>
                      <div>
                        <Potencia
                          height="27.2px"
                          fill={infoJson.Cores.Principal}
                        />
                      </div>
                      <div className={cssD.listagem_specs_info_solo}>
                        <div>Potência</div>
                        <div> {textPotencia}</div>
                      </div>
                    </div>
                  ) : (
                    <div className={cssD.listagem_specs_item_solo}>
                      <div>
                        <Comb
                          height="27.2px"
                          width="27.2px"
                          stroke={infoJson.Cores.Principal}
                        />
                      </div>
                      <div className={cssD.listagem_specs_info_solo}>
                        <div>Combustível</div>
                        <div> {CombustivelTxt}</div>
                      </div>
                    </div>
                  )}

                  <div className={cssD.listagem_specs_item_solo}>
                    <div>
                      <Km height="27.2px" fill={infoJson.Cores.Principal} />
                    </div>
                    <div className={cssD.listagem_specs_info_solo}>
                      <div>Quilómetros</div>
                      <div> {data.Vendido !== true && km ? km : "-"}</div>
                    </div>
                  </div>
                </div>
              </Col>

              {data.NaoExportarPreco !== true && PrecoPromoLimpo !== "" ? (
                <Col xs={12} className={`${cssD.campanha} ${cssD.nocampanha}`}>
                  Campanha
                </Col>
              ) : (
                <Col xs={12} className={cssD.nocampanha}>
                  Preço
                </Col>
              )}

              <Row>
                <Col
                  xs={
                    data.NaoExportarMensalidade !== true &&
                    ValorMensalidadeLimpo !== ""
                      ? 6
                      : 12
                  }
                  className={`boxPrecoFicha  ${cssD.boxPreco}`}
                >
                  {data.NaoExportarPreco !== true && PrecoPromoLimpo !== "" ? (
                    <>
                      <span>{PrecoPromoArray[0]} </span> {PrecoPromoArray[1]}
                      {PrecoPromoArray[2]}
                    </>
                  ) : data.NaoExportarPreco !== true && PrecoLimpo !== "" ? (
                    <>
                      <span>{PrecoArray[0]} </span> {PrecoArray[1]}
                      {PrecoArray[2]}{" "}
                    </>
                  ) : (
                    <> Sob/Consulta</>
                  )}
                </Col>
                {data.NaoExportarMensalidade !== true &&
                  ValorMensalidadeLimpo !== "" && (
                    <Col xs={6} className={cssD.nocampanha}>
                      DESDE <br></br>
                      <b>{ValorMensalidadeLimpo}/MÊS</b>
                    </Col>
                  )}
              </Row>

              {data.NaoExportarPreco !== true && PrecoPromoLimpo !== "" ? (
                <Col
                  xs={12}
                  className={`${cssD.nocampanha} ${css.nocampanhaColor}`}
                >
                  Antes <b>{PrecoLimpo}</b>
                </Col>
              ) : (
                <Col xs={12} className={`${cssD.nocampanha}`}></Col>
              )}
            </Link>

            <CSSTransition
              nodeRef={nodeRef}
              in={opcoes}
              unmountOnExit
              timeout={100}
              classNames={{
                enterActive: `${cssO.opcoesEnter}`,
                enterDone: `${cssO.opcoesEnterActive}`,
                exit: `${cssO.opcoesExit}`,
                exitActive: `${cssO.opcoesExitActive}`,
              }}
            >
              <OpcoesCarro
                nodeRef={nodeRef}
                carro={data}
                selectedItems={selectedItems}
                addToCompare={addToCompare}
                removeFromCompare={removeFromCompare}
                infoJson={infoJson}
                setState={setExit}
              />
            </CSSTransition>
            <div
              onMouseEnter={() => {
                setOpcoes(true);
              }}
              onClick={() => {
                deviceType !== "desktop" && setOpcoes(!opcoes);
              }}
              className={`${css.Mais}  ${opcoes ? "MaisOpen" : "Mais"} `}
            >
              {opcoes ? <Fechar /> : <Mais />}
            </div>
            {infoJson.Layout.Carros.tagTipo === 4 && (
              <Tag
                vendido={data.Vendido}
                reservado={data.Reservado}
                tagImg={data.TagImg}
                precoPromo={data.PrecoPromo}
                dispoBreve={data.DisponivelBrevemente}
                ivaDedutivel={data.PrecoIvaDedutivel}
                ivaDescriminado={data.IvaDiscriminado}
                infoJson={infoJson}
              />
            )}
          </Col>
        </Row>
        <Spacer clasName="col-12 col nopadding" height="30px" />
      </Col>
    </>
  );
}
