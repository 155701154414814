export const ApiLink = "https://ws.easydata.pt";
//export const ApiLink = "http://192.168.0.153:8090";
//export const ApiLink = "http://192.168.1.67:8090";

/* Keys FileStack*/

export const PickerOptions = {
  maxSize: 10 * 1024 * 1024,
  maxFiles: 12,
  fromSources: ["local_file_system"],
  lang: "pt",
  accept: [
    "image/jpeg",
    "image/jpg",
    "image/png",
    "image/bmp",
    "image/gif",
    "application/pdf",
  ],
};
/* Dados */
export const carrosNormais = [
  47, 48, 49, 50, 51, 52, 53, 62, 63, 68, 69, 91, 92, 115, 118,
];
export const carrosComerciais = [
  46, 86, 59, 60, 61, 64, 65, 66, 87, 88, 89, 114, 95, 96, 97, 98, 99, 100, 101,
  102, 103, 104, 105, 106, 107, 108, 109, 110, 111, 112, 113, 117, 119,
];
export const CaixaManual = [33, 34, 35, 40, 42];
export const CaixaAutomatica = [32, 36, 37, 38, 39, 41, 43, 44];
