import React from "react";
import { BodyHeader } from "./BodyHeader";
import { BodyContent } from "./BodyContent";
import { BodyFooter } from "./BodyFooter";
import { Box, Item } from "react-html-email";
import { EmailHeader } from "./EmailHeader";

export const EmailBody = ({
  theme,
  title,
  emailHeaderHref,
  emailHeaderText,
  emailHeaderLogo,
  bodyHeaderText,
  bodyContentComponent,
  bodyFooterText,
}) => (
  <>
    <Item style={{ padding: 0 }}>
      <Box align={theme.emailBody.box.align} style={theme.emailBody.box.style}>
        <EmailHeader
          title={title}
          emailHeaderHref={emailHeaderHref}
          emailHeaderText={emailHeaderText}
          emailHeaderLogo={emailHeaderLogo}
          styles={theme.emailHeader}
        />

        <BodyHeader
          bodyHeaderText={bodyHeaderText}
          styles={theme.emailBody.bodyHeader}
        />

        <Item style={theme.emailBody.line.style}>
          <hr style={theme.emailBody.line.bg} />
        </Item>

        <BodyContent
          bodyContentComponent={bodyContentComponent}
          styles={theme.emailBody.bodyContent}
        />

        <Item style={theme.emailBody.line.style}>
          <hr style={theme.emailBody.line.bg} />
        </Item>

        <BodyFooter
          bodyFooterText={bodyFooterText}
          styles={theme.emailBody.bodyFooter}
        />
      </Box>
    </Item>
  </>
);
