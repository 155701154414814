import axios from "axios";
const global = require("../config/config");
var Fn = require("../components/helpers/tratarFacebook");

// cria uma chamada para ir buscar os dados que estão
// no json da pasta public

async function getListaViaturas(infoJson) {
  try {
    var API_URL_Lista_Viaturas = {
      method: "GET",
      url: `${global.ApiLink}/v1/${
        infoJson.Stand.Easymanager ? "easymanager" : "easydata"
      }/carros/GetListaDetalhesViatura/`,
      params: { dealer_id: infoJson.Stand.Anunciante },
      headers: { token: process.env.REACT_APP_TOKEN_API },
    };

    const res = await axios.request(API_URL_Lista_Viaturas);

    if (res.data.length > 0) {
      const carros = res.data.map((carro) => {
        return {
          ...carro,
          Modelo: {
            ...carro.Modelo,
            NomeCompleto: `${carro.Marca.Nome} ${carro.Modelo.Nome}`,
          },
          Viatura: "carros",
        };
      });
      return carros;
    }
    return [];
  } catch (error) {
    console.error("error", error);
  }
}

async function getAllMarcas(infoJson) {
  try {
    const API_URL_GETMARCAS = {
      method: "GET",
      url: `${global.ApiLink}/v1/${
        infoJson.Stand.Easymanager ? "easymanager" : "easydata"
      }/carros/GetMarcasAll/`,
      params: { dealer_id: infoJson.Stand.Anunciante },
      headers: { token: process.env.REACT_APP_TOKEN_API },
    };
    const res = await axios.request(API_URL_GETMARCAS);

    if (res.data.length > 0) {
      const marcas = res.data.map((marca) => {
        return { ...marca };
      });
      return marcas;
    }
    return [];
  } catch (error) {
    console.error("error", error);
  }
}
async function getAllModelos(codMarca, infoJson) {
  try {
    const API_URL_GETMODELOS = {
      method: "GET",
      url: `${global.ApiLink}/v1/${
        infoJson.Stand.Easymanager ? "easymanager" : "easydata"
      }/carros/GetModelosAll/`,
      params: { dealer_id: infoJson.Stand.Anunciante, brand_id: codMarca },
      headers: { token: process.env.REACT_APP_TOKEN_API },
    };
    const res = await axios.request(API_URL_GETMODELOS);

    if (res.data.length > 0) {
      const modelos = res.data;
      return modelos;
    }
    return [];
  } catch (error) {
    console.error("error", error);
  }
}

async function getExtrasViaturas(codViatura, infoJson) {
  try {
    const API_URL_EXTRAS = {
      method: "GET",
      url: `${global.ApiLink}/v1/${
        infoJson.Stand.Easymanager ? "easymanager" : "easydata"
      }/carros/GetExtrasViaturaFamilia/`,
      params: { dealer_id: infoJson.Stand.Anunciante, cars_id: codViatura },
      headers: { token: process.env.REACT_APP_TOKEN_API },
    };
    const res = await axios.request(API_URL_EXTRAS);

    if (res.data.length > 0) {
      const extras = res.data.map((extra) => {
        return { ...extra };
      });
      return extras;
    }
    return [];
  } catch (error) {
    console.error("error", error);
  }
}

async function getHistoricoViatura(codViatura, infoJson) {
  try {
    const API_URL_HISTORICOVIATURA = {
      method: "GET",
      url: `${global.ApiLink}/v1/${
        infoJson.Stand.Easymanager ? "easymanager" : "easydata"
      }/carros/GetHistoricoViatura/`,
      params: { dealer_id: infoJson.Stand.Anunciante, cars_id: codViatura },
      headers: { token: process.env.REACT_APP_TOKEN_API },
    };

    const res = await axios.request(API_URL_HISTORICOVIATURA);

    if (res.data.length > 0) {
      const intervencoes = res.data.map((intervencao) => {
        return { ...intervencao };
      });
      return intervencoes;
    }
    return [];
  } catch (error) {
    console.error("error", error);
  }
}
async function getAllCombustivel(infoJson) {
  try {
    const API_URL_GETCOMBUSTIVEL = {
      method: "GET",
      url: `${global.ApiLink}/v1/${
        infoJson.Stand.Easymanager ? "easymanager" : "easydata"
      }/carros/GetCombustivelAll/`,
      params: { dealer_id: infoJson.Stand.Anunciante },
      headers: { token: process.env.REACT_APP_TOKEN_API },
    };
    const res = await axios.request(API_URL_GETCOMBUSTIVEL);

    if (res.data.length > 0) {
      const combustiveis = res.data.map((combustivel) => {
        return { ...combustivel };
      });
      return combustiveis;
    }
    return [];
  } catch (error) {
    console.error("error", error);
  }
}

async function getAllDestaques(zona, infoJson) {
  try {
    const API_URL_GET_DESTAQUES = {
      method: "GET",
      url: `${global.ApiLink}/v1/${
        infoJson.Stand.Easymanager ? "easymanager" : "easydata"
      }/carros/GetDestaquesListaViatura/`,
      params: { dealer_id: infoJson.Stand.Anunciante, cod_zona: zona },
      headers: { token: process.env.REACT_APP_TOKEN_API },
    };
    const res = await axios.request(API_URL_GET_DESTAQUES);

    if (res.data.length > 0) {
      const carros = res.data.map((carro) => {
        return {
          ...carro,
          Modelo: {
            ...carro.Modelo,
            NomeCompleto: `${carro.Marca.Nome} ${carro.Modelo.Nome}`,
          },
          Viatura: "carros",
        };
      });
      return carros;
    }
    return [];
  } catch (error) {
    console.error("error", error);
    return [];
  }
}

async function getTextMensalidade(viatura, allinfo, infoJson) {
  try {
    const API_URL_MENSALIDADE = {
      method: "POST",
      url: `${global.ApiLink}/v1/${
        infoJson.Stand.Easymanager ? "easymanager" : "easydata"
      }/carros/GetTextMensalidade/`,
      params: { dealer_id: infoJson.Stand.Anunciante },
      headers: {
        token: process.env.REACT_APP_TOKEN_API,
        "Content-Type": "application/json",
      },
      data: {
        viatura: viatura,
        allinfo: allinfo,
      },
    };
    const res = await axios.request(API_URL_MENSALIDADE);

    const textos = res.data;
    return textos;
  } catch (error) {
    console.error("error", error);
  }
}

async function postFacebook_ServerSide(viatura, allinfo) {
  try {
    if (
      allinfo.FB_PixelID.trim() !== "" &&
      allinfo.FB_AccessToken.trim() !== ""
    ) {
      const codigo_postal = Fn.codigoPostal(allinfo.Cp4, allinfo.Cp3);
      const aux_state = Fn.switchEstado(viatura.Estado.Nome);
      const aux_trans = Fn.switchTransmissao(viatura.Transmissao.Id);
      const aux_tipo_automovel = Fn.switchTipo(viatura.Tipo.Id);
      const tipo_combustivel = Fn.switchCombustivel(viatura.Combustivel.Id);

      const fb_id = allinfo.FB_PixelID;
      const fb_token = allinfo.FB_AccessToken;

      const nomewebsite = allinfo.Website;
      const eventtime = Math.floor(Date.now() / 1000);
      const aux_user_data = {
        fb_login_id: fb_id,
      };

      const aux_custom_data = {
        content_type: "vehicle",
        content_ids: [`${viatura.CodViatura}`], // REQUIRED: array of vehicle IDs
        postal_code: codigo_postal, // RECOMMENDED
        country: "Portugal", // RECOMMENDED  don't use abbreviations
        make: viatura.Marca.Nome, // RECOMMENDED
        model: viatura.Modelo.Nome, // RECOMMENDED
        year: viatura.Ano, // RECOMMENDED
        state_of_vehicle: aux_state, // RECOMMENDED
        exterior_color: viatura.Cor.Name, // RECOMMENDED
        transmission: aux_trans, // RECOMMENDED
        body_style: aux_tipo_automovel, // RECOMMENDED
        fuel_type: tipo_combustivel, // RECOMMENDED
        value: viatura.Preco, // RECOMMENDED, up to 2 decimals optional
        price: viatura.Preco, // RECOMMENDED, up to 2 decimals optional
        currency: "EUR", // REQUIRED if price and preferred_price_range is used
      };
      const formdados = {
        event_name: "ViewContent",
        event_time: eventtime,
        user_data: aux_user_data,

        custom_data: aux_custom_data,
        action_source: "website",
        event_source_url: nomewebsite,
      };
      const fb_link = "https://graph.facebook.com/v14.0/";

      const url = fb_link + fb_id + "/events";

      const form = new FormData();
      form.append("data", "[" + JSON.stringify(formdados) + "]");
      form.append("access_token", fb_token);

      const options = {
        method: "POST",
        url: url,
        headers: {
          "content-type":
            "multipart/form-data; boundary=---011000010111000001101001",
        },
        data: form,
      };

      await axios
        .request(options)
        .then(function (response) {
          console.log(response.data);
        })
        .catch(function (error) {
          console.error(error);
        });
    }
  } catch (error) {
    console.error("error", error);
  }
}

async function getCatalogacaoMarcas(infoJson) {
  try {
    const API_URL_EXTRAS = {
      method: "GET",
      url: `${global.ApiLink}/v1/${
        infoJson.Stand.Easymanager ? "easymanager" : "easydata"
      }/carros/GetCatalogacaoMarcas/`,
      params: { dealer_id: infoJson.Stand.Anunciante },
      headers: { token: process.env.REACT_APP_TOKEN_API },
    };
    const res = await axios.request(API_URL_EXTRAS);

    if (res.data.length > 0) {
      const dados = res.data.map((dado) => {
        return { ...dado };
      });
      return dados;
    }
    return [];
  } catch (error) {
    console.error("error", error);
  }
}

export {
  getListaViaturas,
  getAllMarcas,
  getAllModelos,
  getAllCombustivel,
  getExtrasViaturas,
  getHistoricoViatura,
  getAllDestaques,
  getTextMensalidade,
  postFacebook_ServerSide,
  getCatalogacaoMarcas,
};
