import React, { useEffect, useState } from "react";
import { Range, getTrackBackground } from "react-range";
import { tratarPotencia } from "../../../../helpers/tratarInfoCarros";
import css from "./slider.module.css";
import { ReactComponent as SetaDir } from "../../../../../assets/icons/arrow/seta_dir.svg";
import { ReactComponent as SetaEsq } from "../../../../../assets/icons/arrow/seta_esq.svg";
import useMediaQuery from "../../../../hooks/useMediaQuery ";

const STEP = 1;

export default function PotenciaSlider({
  ArrayCarros,
  Update,
  PesquisaPotenciaInicial,
  infoJson,
  tipoViatura,
}) {
  const ismobile = useMediaQuery("(max-width: 767px)");
  const [values, setValues] = useState([300]);

  const [MIN, setMIN] = useState(0);
  const [MAX, setMAX] = useState(300);

  useEffect(() => {
    const min = Math.min.apply(
      Math,
      ArrayCarros.filter((carro) => {
        return carro.Vendido === false;
      })
        .filter((a) => {
          return a.Potencia > 0 ? true : false;
        })
        .map(function (o) {
          return o.Potencia;
        })
    );

    setMIN(min);

    const max = Math.max.apply(
      Math,
      ArrayCarros.filter((carro) => {
        return carro.Vendido === false;
      })
        .filter((a) => {
          return a.Potencia > 0 ? true : false;
        })
        .map(function (o) {
          return o.Potencia;
        })
    );

    setValues([PesquisaPotenciaInicial !== "" ? PesquisaPotenciaInicial : min]);
    setMAX(max);
  }, [ArrayCarros, PesquisaPotenciaInicial]);

  const handlerValue = (values) => {
    setValues(values);
    Update(values);
  };

  return (
    <>
      {ismobile && (
        <output className={`${css.values}`}>
          Desde {tratarPotencia(values[0], tipoViatura === 3 ? "barcos" : "")}
        </output>
      )}
      <Range
        values={values}
        step={STEP}
        min={MIN}
        max={MAX}
        onChange={(values) => handlerValue(values)}
        renderTrack={({ props, children }) => (
          <div
            //     onMouseDown={props.onMouseDown}
            //    onTouchStart={props.onTouchStart}
            style={{
              ...props.style,
            }}
            className={css.rectangle}
          >
            <div
              ref={props.ref}
              style={{
                height: "2px",
                width: "100%",
                borderRadius: "4px",
                background: getTrackBackground({
                  values,
                  colors: [`${infoJson.Cores.Principal}`, "#ccc"],
                  min: MIN,
                  max: MAX,
                }),
                alignSelf: "center",
              }}
            >
              {children}
            </div>
          </div>
        )}
        renderThumb={({ index, props, isDragged }) => (
          <div
            {...props}
            style={{
              ...props.style,
            }}
            className={`${css.box} box-slider`}
          >
            {ismobile ? (
              <div className={`${css.arrow}`}>
                <SetaEsq stroke={isDragged ? "#b9b9b9" : "#fff"} />
                <SetaDir stroke={isDragged ? "#b9b9b9" : "#fff"} />
              </div>
            ) : (
              <div className={`${css.number}`}>
                {tratarPotencia(
                  values[index],
                  tipoViatura === 3 ? "barcos" : ""
                )}
              </div>
            )}
          </div>
        )}
      />
    </>
  );
}
