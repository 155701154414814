import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/effect-coverflow";
import CardDestaque from "./CardDestaque";
import useMediaQuery from "../../../hooks/useMediaQuery ";

export default function SliderNomal({
  ViaturasDestaques,
  infoJson,
  selectedItems,
  addToCompare,
  removeFromCompare,
}) {
  const ismobile = useMediaQuery("(max-width: 574px)");
  const responsive = {
    0: {
      slidesPerView: 1,
      spaceBetween: 10,
    },
    991: {
      slidesPerView: 3,
      spaceBetween: 20,
    },
  };

  return (
    <Swiper
      className={"destaques-cover-flow"}
      breakpoints={responsive}
      pagination={
        ismobile
          ? {
              dynamicBullets: true,
            }
          : true
      }
      effect={"slider"}
      grabCursor="true"
      centeredSlides={true}
      loop={true}
      speed={500}
      modules={[Autoplay, Pagination, Navigation]}
    >
      {ViaturasDestaques.map((carro) => {
        return (
          <SwiperSlide key={carro.CodViatura}>
            <CardDestaque
              data={carro}
              infoJson={infoJson}
              selectedItems={selectedItems}
              addToCompare={addToCompare}
              removeFromCompare={removeFromCompare}
            />
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
}
