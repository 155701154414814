import Marca from "../Marca";
import Modelos from "../Modelos";
import Transmissao from "../Transmissao";
import Combustivel from "../Combustivel";
import Segmento from "../Segmento";
import Extras from "../Extras";
import MarcaSelect from "../MarcaSelect";
import ModelosSelect from "../ModelosSelect";
import CombustivelSelect from "../CombustivelSelect";
import SegmentoSelect from "../SegmentoSelect";
import TransmissaoSelect from "../TransmissaoSelect";
import ExtrasSelect from "../ExtrasSelect";
import Lotacao from "../Lotacao";
import LotacaoSelect from "../LotacaoSelect";
import AnoSlider from "../AnoSlider";
import PrecoSlider from "../PrecoSlider";
import KmSlider from "../KmSlider";
import PotenciaSlider from "../PotenciaSlider";
import css from "../../pesquisa.module.css";
import HorasSlider from "../HorasSlider";
import ComprimentoSlider from "../ComprimentoSlider";
import Motores from "../Motores";
import MotoresSelect from "../MotoresSelect";

const transmissao = [
  { CodTransmissao: 2, Transmissao: "Automática" },
  { CodTransmissao: 1, Transmissao: "Manual" },
];

export const listFormPesquisa = (
  infoJson,
  ArrayCarros,
  setSelectRefMarca,
  setSelectRefModelo,
  setSelectRefCombustivel,
  setSelectRefTransmissao,
  setSelectRefSegmento,
  setSelectRefExtras,
  modelos,
  setModelos,
  setPesquisaMarcas,
  PesquisaMarcasSelect,
  setPesquisaModelos,
  PesquisaModelosSelect,
  setPesquisaTransmissao,
  PesquisaTransmissaoSelect,
  setPesquisaLotacao,
  PesquisaLotacaoSelect,
  setSelectRefLotacao,
  setPesquisaCombustivel,
  PesquisaCombustivelSelect,
  setPesquisaSegmento,
  PesquisaSegmentoSelect,
  PesquisaExtras,
  setPesquisaExtras,
  PesquisaAnoInicial,
  setPesquisaAnoInicial,
  PesquisaAnoFinal,
  setPesquisaAnoFinal,
  PesquisaKmInicial,
  setPesquisaKmInicial,
  PesquisaKmFinal,
  setPesquisaKmFinal,
  PesquisaPrecoInicial,
  setPesquisaPrecoInicial,
  PesquisaPrecoFinal,
  setPesquisaPrecoFinal,
  PesquisaPotenciaInicial,
  setPesquisaPotenciaInicial,
  PesquisaHorasInicial,
  PesquisaHorasFinal,
  setPesquisaHorasInicial,
  setPesquisaHorasFinal,
  PesquisaComprimentoInicial,
  PesquisaComprimentoFinal,
  setPesquisaComprimentoInicial,
  setPesquisaComprimentoFinal,
  setPesquisaMotores,
  PesquisaMotoresSelect,
  setSelectRefMotores,
  tipoViatura
) => {
  const onChangeMarcas = (marcas, modelos) => {
    setPesquisaMarcas(marcas);
    setModelos(modelos);
  };

  if (tipoViatura === 3) {
    return [
      {
        primeiraLinha: [
          {
            nome: "Marca",
            component:
              PesquisaMarcasSelect.length !== 0 ? (
                <MarcaSelect
                  UpdateSelectRef={setSelectRefMarca}
                  Array={ArrayCarros}
                  onChange={onChangeMarcas}
                  ArraySelect={PesquisaMarcasSelect}
                  infoJson={infoJson}
                />
              ) : (
                <Marca
                  UpdateSelectRef={setSelectRefMarca}
                  Array={ArrayCarros}
                  onChange={onChangeMarcas}
                  infoJson={infoJson}
                  ArraySelect={PesquisaMarcasSelect}
                />
              ),
            xs: 6,
            lg: 3,
          },
          {
            nome: "Propulsão",
            component:
              ArrayCarros.length !== 0 &&
              PesquisaCombustivelSelect.length !== 0 ? (
                <CombustivelSelect
                  UpdateSelectRef={setSelectRefCombustivel}
                  Array={ArrayCarros}
                  onChange={setPesquisaCombustivel}
                  ArraySelect={PesquisaCombustivelSelect}
                  infoJson={infoJson}
                />
              ) : (
                <Combustivel
                  UpdateSelectRef={setSelectRefCombustivel}
                  Array={ArrayCarros}
                  onChange={setPesquisaCombustivel}
                  infoJson={infoJson}
                />
              ),

            xs: 6,
            lg: 3,
          },

          {
            nome: "Intervalo de Preço",
            component: (
              <PrecoSlider
                ArrayCarros={ArrayCarros}
                PesquisaPrecoInicial={PesquisaPrecoInicial}
                PesquisaPrecoFinal={PesquisaPrecoFinal}
                UpdateInicial={setPesquisaPrecoInicial}
                UpdateFinal={setPesquisaPrecoFinal}
                infoJson={infoJson}
              />
            ),
            xs: 12,
            lg: 6,
          },
        ],
        segundaLinha: [
          {
            nome: "Ano",
            component: (
              <AnoSlider
                PesquisaAnoInicial={PesquisaAnoInicial}
                PesquisaAnoFinal={PesquisaAnoFinal}
                ArrayCarros={ArrayCarros}
                UpdateInicial={setPesquisaAnoInicial}
                UpdateFinal={setPesquisaAnoFinal}
                infoJson={infoJson}
              />
            ),
            xs: 12,
            lg: 6,
          },
          {
            nome: "Horas",
            component: (
              <HorasSlider
                PesquisaHorasInicial={PesquisaHorasInicial}
                PesquisaHorasFinal={PesquisaHorasFinal}
                ArrayCarros={ArrayCarros}
                UpdateInicial={setPesquisaHorasInicial}
                UpdateFinal={setPesquisaHorasFinal}
                infoJson={infoJson}
              />
            ),
            xs: 12,
            lg: 6,
          },
        ],
        terceiraLinha: [
          {
            nome: "Comprimento",
            component: (
              <ComprimentoSlider
                PesquisaComprimentoInicial={PesquisaComprimentoInicial}
                PesquisaComprimentoFinal={PesquisaComprimentoFinal}
                ArrayCarros={ArrayCarros}
                UpdateInicial={setPesquisaComprimentoInicial}
                UpdateFinal={setPesquisaComprimentoFinal}
                infoJson={infoJson}
              />
            ),
            xs: 12,
            lg: 6,
          },
          {
            nome: "Potência",
            component: (
              <PotenciaSlider
                PesquisaPotenciaInicial={PesquisaPotenciaInicial}
                ArrayCarros={ArrayCarros}
                Update={setPesquisaPotenciaInicial}
                infoJson={infoJson}
                tipoViatura={tipoViatura}
              />
            ),
            xs: 12,
            lg: 3,
          },

          {
            nome: "Segmento",
            component:
              ArrayCarros.length !== 0 &&
              PesquisaSegmentoSelect.length !== 0 ? (
                <SegmentoSelect
                  UpdateSelectRef={setSelectRefSegmento}
                  Array={ArrayCarros}
                  onChange={setPesquisaSegmento}
                  ArraySelect={PesquisaSegmentoSelect}
                  infoJson={infoJson}
                />
              ) : (
                <Segmento
                  UpdateSelectRef={setSelectRefSegmento}
                  Array={ArrayCarros}
                  onChange={setPesquisaSegmento}
                  infoJson={infoJson}
                />
              ),
            xs: 12,
            lg: 3,
          },
        ],
        quartaLinha: [
          {
            nome: "Numero de Motores",
            component:
              ArrayCarros.length !== 0 && PesquisaMotoresSelect.length !== 0 ? (
                <MotoresSelect
                  UpdateSelectRef={setSelectRefMotores}
                  Array={ArrayCarros}
                  onChange={setPesquisaMotores}
                  ArraySelect={PesquisaMotoresSelect}
                  infoJson={infoJson}
                />
              ) : (
                <Motores
                  UpdateSelectRef={setSelectRefMotores}
                  Array={ArrayCarros}
                  onChange={setPesquisaMotores}
                  infoJson={infoJson}
                />
              ),
            xs: 12,
            lg: 3,
          },
          {
            nome: "Extras",
            component:
              ArrayCarros.length !== 0 && PesquisaExtras.length !== 0 ? (
                <ExtrasSelect
                  UpdateSelectRef={setSelectRefExtras}
                  Array={ArrayCarros}
                  onChange={setPesquisaExtras}
                  ArraySelect={PesquisaExtras}
                  infoJson={infoJson}
                />
              ) : (
                <Extras
                  UpdateSelectRef={setSelectRefExtras}
                  Array={ArrayCarros}
                  onChange={setPesquisaExtras}
                  infoJson={infoJson}
                />
              ),
            xs: 12,
            lg: 9,
          },
        ],
      },
    ];
  } else {
    return [
      {
        primeiraLinha: [
          {
            nome: "Marca",
            component:
              PesquisaMarcasSelect.length !== 0 ? (
                <MarcaSelect
                  UpdateSelectRef={setSelectRefMarca}
                  Array={ArrayCarros}
                  onChange={onChangeMarcas}
                  ArraySelect={PesquisaMarcasSelect}
                  infoJson={infoJson}
                />
              ) : (
                <Marca
                  UpdateSelectRef={setSelectRefMarca}
                  Array={ArrayCarros}
                  onChange={onChangeMarcas}
                  infoJson={infoJson}
                  ArraySelect={PesquisaMarcasSelect}
                />
              ),
            xs: 6,
            lg: 3,
          },
          {
            nome: "Modelo",
            component:
              modelos.length === 0 && PesquisaModelosSelect.length === 0 ? (
                <div className={`${css.btnPesquisaModelo} selectSvg`}>
                  Selecionar
                </div>
              ) : modelos.length !== 0 && PesquisaModelosSelect.length !== 0 ? (
                <ModelosSelect
                  Array={modelos}
                  onChange={setPesquisaModelos}
                  ArraySelect={PesquisaModelosSelect}
                  UpdateSelectRef={setSelectRefModelo}
                  infoJson={infoJson}
                />
              ) : (
                <Modelos
                  Array={modelos}
                  onChange={setPesquisaModelos}
                  UpdateSelectRef={setSelectRefModelo}
                  infoJson={infoJson}
                />
              ),
            xs: 6,
            lg: 3,
          },
          {
            nome: "Intervalo de Preço",
            component: (
              <PrecoSlider
                ArrayCarros={ArrayCarros}
                PesquisaPrecoInicial={PesquisaPrecoInicial}
                PesquisaPrecoFinal={PesquisaPrecoFinal}
                UpdateInicial={setPesquisaPrecoInicial}
                UpdateFinal={setPesquisaPrecoFinal}
                infoJson={infoJson}
              />
            ),
            xs: 12,
            lg: 6,
          },
        ],
        segundaLinha: [
          {
            nome: "Ano",
            component: (
              <AnoSlider
                PesquisaAnoInicial={PesquisaAnoInicial}
                PesquisaAnoFinal={PesquisaAnoFinal}
                ArrayCarros={ArrayCarros}
                UpdateInicial={setPesquisaAnoInicial}
                UpdateFinal={setPesquisaAnoFinal}
                infoJson={infoJson}
              />
            ),
            xs: 12,
            lg: 6,
          },
          {
            nome: "Quilómetros",
            component: (
              <KmSlider
                PesquisaKmInicial={PesquisaKmInicial}
                PesquisaKmFinal={PesquisaKmFinal}
                ArrayCarros={ArrayCarros}
                UpdateInicial={setPesquisaKmInicial}
                UpdateFinal={setPesquisaKmFinal}
                infoJson={infoJson}
              />
            ),
            xs: 12,
            lg: 6,
          },
        ],
        terceiraLinha: [
          {
            nome: "Potência",
            component: (
              <PotenciaSlider
                PesquisaPotenciaInicial={PesquisaPotenciaInicial}
                ArrayCarros={ArrayCarros}
                Update={setPesquisaPotenciaInicial}
                infoJson={infoJson}
                tipoViatura={tipoViatura}
              />
            ),
            xs: 12,
            lg: 3,
          },
          {
            nome: "Transmissão",
            component:
              ArrayCarros.length !== 0 &&
              PesquisaTransmissaoSelect.length !== 0 ? (
                <TransmissaoSelect
                  UpdateSelectRef={setSelectRefTransmissao}
                  Array={transmissao}
                  onChange={setPesquisaTransmissao}
                  ArraySelect={PesquisaTransmissaoSelect}
                  infoJson={infoJson}
                />
              ) : (
                <Transmissao
                  UpdateSelectRef={setSelectRefTransmissao}
                  Array={transmissao}
                  onChange={setPesquisaTransmissao}
                  infoJson={infoJson}
                />
              ),
            xs: 12,
            lg: 3,
          },
          {
            nome: "Combustivel",
            component:
              ArrayCarros.length !== 0 &&
              PesquisaCombustivelSelect.length !== 0 ? (
                <CombustivelSelect
                  UpdateSelectRef={setSelectRefCombustivel}
                  Array={ArrayCarros}
                  onChange={setPesquisaCombustivel}
                  ArraySelect={PesquisaCombustivelSelect}
                  infoJson={infoJson}
                />
              ) : (
                <Combustivel
                  UpdateSelectRef={setSelectRefCombustivel}
                  Array={ArrayCarros}
                  onChange={setPesquisaCombustivel}
                  infoJson={infoJson}
                />
              ),
            xs: 6,
            lg: 3,
          },
          {
            nome: "Segmento",
            component:
              ArrayCarros.length !== 0 &&
              PesquisaSegmentoSelect.length !== 0 ? (
                <SegmentoSelect
                  UpdateSelectRef={setSelectRefSegmento}
                  Array={ArrayCarros}
                  onChange={setPesquisaSegmento}
                  ArraySelect={PesquisaSegmentoSelect}
                  infoJson={infoJson}
                />
              ) : (
                <Segmento
                  UpdateSelectRef={setSelectRefSegmento}
                  Array={ArrayCarros}
                  onChange={setPesquisaSegmento}
                  infoJson={infoJson}
                />
              ),
            xs: 6,
            lg: 3,
          },
        ],
        quartaLinha: [
          {
            nome: "Lotação",
            component:
              ArrayCarros.length !== 0 && PesquisaLotacaoSelect.length !== 0 ? (
                <LotacaoSelect
                  UpdateSelectRef={setSelectRefLotacao}
                  Array={ArrayCarros}
                  onChange={setPesquisaLotacao}
                  ArraySelect={PesquisaLotacaoSelect}
                  infoJson={infoJson}
                />
              ) : (
                <Lotacao
                  UpdateSelectRef={setSelectRefLotacao}
                  Array={ArrayCarros}
                  onChange={setPesquisaLotacao}
                  infoJson={infoJson}
                />
              ),
            xs: 12,
            lg: 3,
          },
          {
            nome: "Extras",
            component:
              ArrayCarros.length !== 0 && PesquisaExtras.length !== 0 ? (
                <ExtrasSelect
                  UpdateSelectRef={setSelectRefExtras}
                  Array={ArrayCarros}
                  onChange={setPesquisaExtras}
                  ArraySelect={PesquisaExtras}
                  infoJson={infoJson}
                />
              ) : (
                <Extras
                  UpdateSelectRef={setSelectRefExtras}
                  Array={ArrayCarros}
                  onChange={setPesquisaExtras}
                  infoJson={infoJson}
                />
              ),
            xs: 12,
            lg: 9,
          },
        ],
      },
    ];
  }
};
