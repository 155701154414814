import axios from "axios";

const global = require("../config/config");

async function getListaMotas(infoJson) {
  try {
    var API_URL_Lista_Motas = {
      method: "GET",
      url: `${global.ApiLink}/v1/easydata/motas/GetListaMotas/`,
      params: { dealer_id: infoJson.Stand.Anunciante },
      headers: { token: process.env.REACT_APP_TOKEN_API },
    };

    const res = await axios.request(API_URL_Lista_Motas);

    if (res.data.length > 0) {
      const carros = res.data.map((motas) => {
        return {
          ...motas,
          Modelo: {
            ...motas.Modelo,
            NomeCompleto: `${motas.Marca.Nome} ${motas.Modelo.Nome}`,
          },
          Viatura: "motas",
        };
      });
      return carros;
    }
    return [];
  } catch (error) {
    console.error("error", error);
  }
}

export { getListaMotas };
