import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { Col, Form, Row } from "react-bootstrap";
import css from "../pesquisa.module.css";

import pesquisa from "../../../../../assets/icons/pesquisa/pesquisa.svg";
import {
  createUrl,
  NumeroAtualizarPesquisa,
} from "../../../../helpers/tratarInfoCarros";
import * as apiDados from "../../../../../api/apiDados";

import { ReactComponent as Limpar } from "../../../../../assets/icons/pesquisa/limpar.svg";
import Spacer from "../../Spacer";
import useVerificaUrlPesquisa from "../../../../hooks/useVerificaUrlPesquisa";
import { listFormPesquisa } from "./hooks/listForm";

export default function FormPesquisaAvancada({
  ArrayCarros,
  url,
  tipo,
  setFilteredCarros,
  infoJson,
  tipoViatura,
  allLista,
}) {
  const navigate = useNavigate();

  const [NumeroResultados, setNumeroResultados] = useState([0]);
  const [selectRefMarca, setSelectRefMarca] = useState([]);
  const [selectRefModelo, setSelectRefModelo] = useState([]);
  const [selectRefCombustivel, setSelectRefCombustivel] = useState([]);
  const [selectRefTransmissao, setSelectRefTransmissao] = useState([]);
  const [selectRefSegmento, setSelectRefSegmento] = useState([]);
  const [selectRefExtras, setSelectRefExtras] = useState([]);
  const [modelos, setModelos] = useState([]);

  const [PesquisaMarcas, setPesquisaMarcas] = useState([]);
  const [PesquisaMarcasSelect, setPesquisaMarcasSelect] = useState([]);
  const [PesquisaModelos, setPesquisaModelos] = useState([]);
  const [PesquisaModelosSelect, setPesquisaModelosSelect] = useState([]);
  const [PesquisaTransmissao, setPesquisaTransmissao] = useState([]);
  const [PesquisaTransmissaoSelect, setPesquisaTransmissaoSelect] = useState(
    []
  );
  const [PesquisaLotacao, setPesquisaLotacao] = useState([]);
  const [PesquisaLotacaoSelect, setPesquisaLotacaoSelect] = useState([]);
  const [selectRefLotacao, setSelectRefLotacao] = useState([]);
  const [PesquisaCombustivel, setPesquisaCombustivel] = useState([]);
  const [PesquisaCombustivelSelect, setPesquisaCombustivelSelect] = useState(
    []
  );
  const [PesquisaSegmento, setPesquisaSegmento] = useState([]);
  const [PesquisaSegmentoSelect, setPesquisaSegmentoSelect] = useState([]);
  const [PesquisaExtras, setPesquisaExtras] = useState([]);
  const [PesquisaAnoInicial, setPesquisaAnoInicial] = useState("");
  const [PesquisaAnoFinal, setPesquisaAnoFinal] = useState("");
  const [PesquisaKmInicial, setPesquisaKmInicial] = useState("");
  const [PesquisaKmFinal, setPesquisaKmFinal] = useState("");
  const [PesquisaPrecoInicial, setPesquisaPrecoInicial] = useState("");
  const [PesquisaPrecoFinal, setPesquisaPrecoFinal] = useState("");
  const [PesquisaPotenciaInicial, setPesquisaPotenciaInicial] = useState("");

  const [PesquisaHorasInicial, setPesquisaHorasInicial] = useState("");
  const [PesquisaHorasFinal, setPesquisaHorasFinal] = useState("");
  const [PesquisaComprimentoInicial, setPesquisaComprimentoInicial] =
    useState("");
  const [PesquisaComprimentoFinal, setPesquisaComprimentoFinal] = useState("");

  const [PesquisaMotores, setPesquisaMotores] = useState([]);
  const [PesquisaMotoresSelect, setPesquisaMotoresSelect] = useState([]);
  const [selectRefMotores, setSelectRefMotores] = useState([]);

  useEffect(() => {
    const number = ArrayCarros.filter((item) => item.Vendido === false);
    setNumeroResultados(number.length);
  }, [ArrayCarros]);

  useVerificaUrlPesquisa(
    ArrayCarros,
    tipo,
    url,
    setFilteredCarros,
    setPesquisaMarcas,
    setPesquisaMarcasSelect,
    setModelos,
    setPesquisaModelos,
    setPesquisaModelosSelect,
    setPesquisaCombustivel,
    setPesquisaCombustivelSelect,
    setPesquisaTransmissao,
    setPesquisaTransmissaoSelect,
    setPesquisaSegmento,
    setPesquisaSegmentoSelect,
    setPesquisaExtras,
    setPesquisaAnoInicial,
    setPesquisaAnoFinal,
    setPesquisaKmInicial,
    setPesquisaKmFinal,
    setPesquisaPrecoInicial,
    setPesquisaPrecoFinal,
    setPesquisaPotenciaInicial,
    setPesquisaLotacao,
    setPesquisaLotacaoSelect,
    setPesquisaHorasInicial,
    setPesquisaHorasFinal,
    setPesquisaComprimentoInicial,
    setPesquisaComprimentoFinal,
    setPesquisaMotores,
    setPesquisaMotoresSelect,
    infoJson,
    allLista
  );

  const clearAll = () => {
    setPesquisaAnoInicial("");
    setPesquisaAnoFinal("");
    setPesquisaKmInicial("");
    setPesquisaKmFinal("");
    setPesquisaPrecoInicial("");
    setPesquisaPrecoFinal("");
    setPesquisaPotenciaInicial("");
    setPesquisaMarcas([]);
    setPesquisaModelos([]);
    setPesquisaMarcasSelect([]);
    setPesquisaModelosSelect([]);
    setPesquisaHorasInicial("");
    setPesquisaHorasFinal("");
    setPesquisaComprimentoInicial("");
    setPesquisaComprimentoFinal("");
    setPesquisaMarcasSelect([]);
    setPesquisaModelosSelect([]);
    selectRefMotores.current?.clearValue();
    selectRefMarca.current?.clearValue();
    selectRefMarca.current?.clearValue();
    selectRefModelo.current?.clearValue();
    selectRefLotacao.current?.clearValue();
    selectRefCombustivel.current?.clearValue();
    selectRefTransmissao.current?.clearValue();
    selectRefSegmento.current?.clearValue();
    selectRefExtras.current?.clearValue();
    setNumeroResultados(
      ArrayCarros.filter((item) => item.Vendido === false).length
    );
  };

  const handleSubmit = (e) => {
    let params = new URLSearchParams();
    // verifica se existe parametros na query

    PesquisaCombustivel.length !== 0 &&
      params.append("Combustivel", PesquisaCombustivel);
    PesquisaSegmento.length !== 0 &&
      params.append("Segmento", PesquisaSegmento);
    PesquisaLotacao.length !== 0 && params.append("Lotacao", PesquisaLotacao);
    PesquisaMotores.length !== 0 && params.append("Motores", PesquisaMotores);
    PesquisaTransmissao.length !== 0 &&
      params.append("Transmissao", PesquisaTransmissao);
    PesquisaExtras.length !== 0 && params.append("Extras", PesquisaExtras);
    PesquisaAnoInicial !== "" &&
      params.append("AnoInicial", PesquisaAnoInicial);
    PesquisaAnoFinal !== "" && params.append("AnoFinal", PesquisaAnoFinal);
    PesquisaPrecoInicial !== "" &&
      params.append("PrecoInicial", PesquisaPrecoInicial);
    PesquisaPrecoFinal !== "" &&
      params.append("PrecoFinal", PesquisaPrecoFinal);
    PesquisaKmInicial !== "" && params.append("KmInicial", PesquisaKmInicial);
    PesquisaKmFinal !== "" && params.append("KmFinal", PesquisaKmFinal);
    PesquisaHorasInicial !== "" &&
      params.append("HorasInicial", PesquisaHorasInicial);
    PesquisaHorasFinal !== "" &&
      params.append("HorasFinal", PesquisaHorasFinal);
    PesquisaComprimentoInicial !== "" &&
      params.append("ComprimentoInicial", PesquisaComprimentoInicial);
    PesquisaComprimentoFinal !== "" &&
      params.append("ComprimentoFinal", PesquisaComprimentoFinal);
    PesquisaPotenciaInicial !== "" &&
      params.append("PotenciaInicial", PesquisaPotenciaInicial);
    e.preventDefault();
    if (tipo === url ? url : "/") {
      const path = `/${tipo}/${
        PesquisaMarcas.length !== 0
          ? `${createUrl(ArrayCarros, "marca", PesquisaMarcas)}/`
          : ""
      }${
        PesquisaModelos.length !== 0
          ? `${createUrl(ArrayCarros, "modelo", PesquisaModelos)}/`
          : ""
      }${params.toString() !== "" ? `?${params.toString()}` : ""}`;

      navigate(
        `${path}${infoJson.Layout.Carros.existe ? "#listagem_viaturas" : ""}`,
        { replace: true, state: { goToListagem: true } }
      );

      if (
        PesquisaMarcas.length !== 0 ||
        PesquisaModelos.length !== 0 ||
        PesquisaCombustivel.length !== 0 ||
        PesquisaSegmento.length !== 0 ||
        PesquisaTransmissao.length !== 0 ||
        PesquisaExtras.length !== 0 ||
        PesquisaAnoInicial !== "" ||
        PesquisaAnoFinal !== "" ||
        PesquisaPrecoInicial !== "" ||
        PesquisaPrecoFinal !== "" ||
        PesquisaKmInicial !== "" ||
        PesquisaKmFinal !== "" ||
        PesquisaPotenciaInicial !== ""
      ) {
        apiDados.InsertDados(
          PesquisaMarcas,
          PesquisaModelos,
          PesquisaCombustivel,
          PesquisaSegmento,
          PesquisaTransmissao,
          PesquisaExtras,
          PesquisaAnoInicial,
          PesquisaAnoFinal,
          PesquisaPrecoInicial,
          PesquisaPrecoFinal,
          PesquisaKmInicial,
          PesquisaKmFinal,
          PesquisaPotenciaInicial,
          tipoViatura,
          infoJson
        );
      }
    } else {
      const path = `/${tipo}/${
        PesquisaMarcas.length !== 0
          ? `${createUrl(ArrayCarros, "marca", PesquisaMarcas)}/`
          : ""
      }${
        PesquisaModelos.length !== 0
          ? `${createUrl(ArrayCarros, "modelo", PesquisaModelos)}/`
          : ""
      }${params.toString() !== "" ? `?${params.toString()}` : ""}`;

      navigate(
        `${path}${infoJson.Layout.Carros.existe ? "#listagem_viaturas" : ""}`,
        { replace: true }
      );
    }
  };

  useEffect(() => {
    const valor = NumeroAtualizarPesquisa(
      PesquisaMarcas,
      PesquisaModelos,
      PesquisaCombustivel,
      PesquisaSegmento,
      PesquisaTransmissao,
      PesquisaExtras,
      PesquisaAnoInicial,
      PesquisaAnoFinal,
      PesquisaPrecoInicial,
      PesquisaPrecoFinal,
      PesquisaKmInicial,
      PesquisaKmFinal,
      PesquisaPotenciaInicial,
      PesquisaLotacao,
      PesquisaHorasInicial,
      PesquisaHorasFinal,
      PesquisaComprimentoInicial,
      PesquisaComprimentoFinal,
      PesquisaMotores,
      ArrayCarros
    );
    setNumeroResultados(valor);
  }, [
    PesquisaMarcas,
    PesquisaModelos,
    PesquisaCombustivel,
    PesquisaSegmento,
    PesquisaTransmissao,
    PesquisaExtras,
    PesquisaAnoInicial,
    PesquisaAnoFinal,
    PesquisaPrecoInicial,
    PesquisaPrecoFinal,
    PesquisaKmInicial,
    PesquisaKmFinal,
    PesquisaPotenciaInicial,
    PesquisaLotacao,
    PesquisaHorasInicial,
    PesquisaHorasFinal,
    PesquisaComprimentoInicial,
    PesquisaComprimentoFinal,
    PesquisaMotores,
    ArrayCarros,
  ]);

  return (
    <>
      {ArrayCarros.filter((item) => item.Vendido === false).length !== 0 && (
        <Form onSubmit={handleSubmit} action={tipo}>
          <Col xs={12}>
            {listFormPesquisa(
              infoJson,
              ArrayCarros,
              setSelectRefMarca,
              setSelectRefModelo,
              setSelectRefCombustivel,
              setSelectRefTransmissao,
              setSelectRefSegmento,
              setSelectRefExtras,
              modelos,
              setModelos,
              setPesquisaMarcas,
              PesquisaMarcasSelect,
              setPesquisaModelos,
              PesquisaModelosSelect,
              setPesquisaTransmissao,
              PesquisaTransmissaoSelect,
              setPesquisaLotacao,
              PesquisaLotacaoSelect,
              setSelectRefLotacao,
              setPesquisaCombustivel,
              PesquisaCombustivelSelect,
              setPesquisaSegmento,
              PesquisaSegmentoSelect,
              PesquisaExtras,
              setPesquisaExtras,
              PesquisaAnoInicial,
              setPesquisaAnoInicial,
              PesquisaAnoFinal,
              setPesquisaAnoFinal,
              PesquisaKmInicial,
              setPesquisaKmInicial,
              PesquisaKmFinal,
              setPesquisaKmFinal,
              PesquisaPrecoInicial,
              setPesquisaPrecoInicial,
              PesquisaPrecoFinal,
              setPesquisaPrecoFinal,
              PesquisaPotenciaInicial,
              setPesquisaPotenciaInicial,
              PesquisaHorasInicial,
              PesquisaHorasFinal,
              setPesquisaHorasInicial,
              setPesquisaHorasFinal,
              PesquisaComprimentoInicial,
              PesquisaComprimentoFinal,
              setPesquisaComprimentoInicial,
              setPesquisaComprimentoFinal,
              setPesquisaMotores,
              PesquisaMotoresSelect,
              setSelectRefMotores,
              tipoViatura
            )
              .sort((a, b) => a.group - b.group)
              .map((item) => {
                return Object.values(item).map((item, index) => {
                  return (
                    <Form.Group xs={12} as={Col} key={url + index + "1000"}>
                      <Row>
                        {item.map((item, index) => {
                          return (
                            <Col xs={item.xs} lg={item.lg} key={url + index}>
                              <Col
                                xs={12}
                                className={`${css.boxShadowPares} boxShadowPares mb-3`}
                              >
                                <label>{item.nome} </label>
                                {item.component}
                              </Col>
                            </Col>
                          );
                        })}
                      </Row>
                    </Form.Group>
                  );
                });
              })}

            <div className={css.btnPequisaBox}>
              <Col xs={12} className="text-center">
                <button
                  className={` btnPesquisa ${css.btnPequisa}`}
                  type="submit"
                >
                  {infoJson.Layout.Carros.NumeroCarros ? (
                    <>
                      Ver {NumeroResultados} resultados{" "}
                      <img
                        height={"18px"}
                        width={"18px"}
                        src={pesquisa}
                        style={{ marginLeft: "5px" }}
                        alt="pesquisar"
                      />
                    </>
                  ) : (
                    <>
                      Ver resultados{" "}
                      <img
                        height={"18px"}
                        width={"18px"}
                        src={pesquisa}
                        style={{ marginLeft: "5px" }}
                        alt="pesquisar"
                      />
                    </>
                  )}
                </button>
              </Col>
              <Col xs={12}>
                <div
                  onClick={clearAll}
                  className={`${css.textoLimpar} textoLimpar `}
                >
                  <Limpar /> Limpar Parâmetros
                </div>
              </Col>
            </div>
          </Col>
          <Spacer clasName="d-block" height="24px" />
        </Form>
      )}
    </>
  );
}
