import React from "react";
import { Span, A, Item, Box } from "react-html-email";
import logo from "../../../../../assets/icons/email/contact.svg";

export const EmailHeader = (props) => (
  <Item style={props.styles.item}>
    <Box style={{ width: "100%" }}>
      <tr>
        <td style={{ width: "45px", verticalAlign: "middle" }}>
          <img src={logo} alt={props.title} />
        </td>
        <td style={{ verticalAlign: "middle" }}>
          <props.emailHeaderText />
        </td>
        <td
          style={{
            width: "157px",
            verticalAlign: "middle",
            textAlign: "right",
          }}
        >
          <Span>
            <A
              href={props.emailHeaderHref}
              textDecoration={props.styles.a.textDecoration}
              style={props.styles.a}
            >
              {props.emailHeaderLogo && (
                <img
                  style={props.styles.logo}
                  src={props.emailHeaderLogo}
                  alt={props.title}
                />
              )}
              {!props.emailHeaderLogo && props.title}
            </A>
          </Span>
        </td>
      </tr>
    </Box>
  </Item>
);
