import React from "react";
import AuthContext from "./AuthContext";
import IsFicha from "./IsFicha";

const usePontosVenda = () => {
  const [pontosVenda, setPontosVenda] = React.useState([]);
  const { state } = React.useContext(AuthContext);
  const ficha = IsFicha();

  React.useEffect(() => {
    if (ficha) {
      const infoStand = state.allinfo.filter((empresa) => {
        return empresa.CodPontoVenda === state.codPontoVenda;
      });
      setPontosVenda(infoStand);
    } else {
      setPontosVenda(state.allinfo);
    }
  }, [ficha, state.codPontoVenda, state.allinfo]);

  return pontosVenda;
};

export default usePontosVenda;
