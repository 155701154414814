import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import {
  tratarAnoMes,
  tratarCilindrada,
  tratarKm,
  tratarNacional,
  tratarPotencia,
} from "../../helpers/tratarInfoCarros";
import css from "./comparison.module.css";

export default function ComparisonAdvance({ selectedItems, size }) {
  return (
    <section className={`${css.sectonCarate} section-comparar`}>
      <div className={`${css.lineCarat} lineCarat`}>
        <Container fluid="xxxl">
          <Row>
            <Col>Ano</Col>
            {selectedItems.slice(0, size).map((item, index) => {
              const ano = tratarAnoMes(item.Ano, item.Mes);
              return (
                <Col xs={6} md={4} lg={3} key={index}>
                  {ano !== "" ? ano : "-"}
                </Col>
              );
            })}
          </Row>
        </Container>
      </div>
      <div className={`${css.lineCarat} lineCarat`}>
        <Container fluid="xxxl">
          <Row>
            <Col>Quilómetros</Col>
            {selectedItems.slice(0, size).map((item, index) => {
              const km = tratarKm(item.Km);
              return (
                <Col xs={6} md={4} lg={3} key={index}>
                  {item.Km && km !== "" ? km : "-"}
                </Col>
              );
            })}
          </Row>
        </Container>
      </div>
      <div className={`${css.lineCarat} lineCarat`}>
        <Container fluid="xxxl">
          <Row>
            <Col>Combustível</Col>
            {selectedItems.slice(0, size).map((item, index) => (
              <Col xs={6} md={4} lg={3} key={index}>
                {item.Combustivel.Nome !== "" ? item.Combustivel.Nome : "-"}
              </Col>
            ))}
          </Row>
        </Container>
      </div>
      <div className={`${css.lineCarat} lineCarat`}>
        <Container fluid="xxxl">
          <Row>
            <Col>Potência</Col>
            {selectedItems.slice(0, size).map((item, index) => (
              <Col xs={6} md={4} lg={3} key={index}>
                {item.Potencia !== ""
                  ? tratarPotencia(item.Potencia, item.Viatura)
                  : "-"}
              </Col>
            ))}
          </Row>
        </Container>
      </div>
      <div className={`${css.lineCarat} lineCarat`}>
        <Container fluid="xxxl">
          <Row>
            <Col>Cilindrada</Col>
            {selectedItems.slice(0, size).map((item, index) => (
              <Col xs={6} md={4} lg={3} key={index}>
                {item.Cilindrada !== ""
                  ? tratarCilindrada(item.Cilindrada)
                  : "-"}
              </Col>
            ))}
          </Row>
        </Container>
      </div>

      <div className={`${css.lineCarat} lineCarat`}>
        <Container fluid="xxxl">
          <Row>
            <Col>Transmissão</Col>
            {selectedItems.slice(0, size).map((item, index) => (
              <Col xs={6} md={4} lg={3} key={index}>
                {item.Transmissao && item.Transmissao.Nome !== ""
                  ? item.Transmissao.Nome
                  : "-"}
              </Col>
            ))}
          </Row>
        </Container>
      </div>

      <div className={`${css.lineCarat} lineCarat`}>
        <Container fluid="xxxl">
          <Row>
            <Col>Segmento</Col>
            {selectedItems.slice(0, size).map((item, index) => (
              <Col xs={6} md={4} lg={3} key={index}>
                {item.Tipo.Nome !== "" ? item.Tipo.Nome : "-"}
              </Col>
            ))}
          </Row>
        </Container>
      </div>
      <div className={`${css.lineCarat} lineCarat`}>
        <Container fluid="xxxl">
          <Row>
            <Col>Emissões</Col>
            {selectedItems.slice(0, size).map((item, index) => (
              <Col xs={6} md={4} lg={3} key={index}>
                {item.Co2 ? `${item.Co2}g/km` : "-"}
              </Col>
            ))}
          </Row>
        </Container>
      </div>
      <div className={`${css.lineCarat} lineCarat`}>
        <Container fluid="xxxl">
          <Row>
            <Col>Portas</Col>
            {selectedItems.slice(0, size).map((item, index) => (
              <Col xs={6} md={4} lg={3} key={index}>
                {item.Porta
                  ? item.Porta.Nome !== ""
                    ? item.Porta.Nome
                    : "-"
                  : "-"}
              </Col>
            ))}
          </Row>
        </Container>
      </div>
      <div className={`${css.lineCarat} lineCarat`}>
        <Container fluid="xxxl">
          <Row>
            <Col>Lugares</Col>
            {selectedItems.slice(0, size).map((item, index) => (
              <Col xs={6} md={4} lg={3} key={index}>
                {item.Viatura === "barcos"
                  ? item.Lugares !== ""
                    ? item.Lugares
                    : "-"
                  : item.Lugares.Nome !== ""
                  ? item.Lugares.Nome
                  : "-"}
              </Col>
            ))}
          </Row>
        </Container>
      </div>
      <div className={`${css.lineCarat} lineCarat`}>
        <Container fluid="xxxl">
          <Row>
            <Col>Cor</Col>
            {selectedItems.slice(0, size).map((item, index) => (
              <Col xs={6} md={4} lg={3} key={index}>
                {item.Cor.Nome !== "" ? item.Cor.Nome : "-"}
              </Col>
            ))}
          </Row>
        </Container>
      </div>
      <div className={`${css.lineCarat} lineCarat`}>
        <Container fluid="xxxl">
          <Row>
            <Col>Registos</Col>
            {selectedItems.slice(0, size).map((item, index) => {
              return (
                <Col xs={6} md={4} lg={3} key={index}>
                  {item.Registo !== "" ? item.Registo : "-"}
                </Col>
              );
            })}
          </Row>
        </Container>
      </div>
      <div className={`${css.lineCarat} lineCarat`}>
        <Container fluid="xxxl">
          <Row>
            <Col>Origem</Col>
            {selectedItems.slice(0, size).map((item, index) => (
              <Col xs={6} md={4} lg={3} key={index}>
                {item.Nacional && item.Nacional !== ""
                  ? tratarNacional(item.Nacional)
                  : "-"}
              </Col>
            ))}
          </Row>
        </Container>
      </div>
      <div className={`${css.lineCarat} lineCarat`}>
        <Container fluid="xxxl">
          <Row>
            <Col>Garantia</Col>
            {selectedItems.slice(0, size).map((item, index) => (
              <Col xs={6} md={4} lg={3} key={index}>
                {item.Garantia.Nome !== "" ? item.Garantia.Nome : "-"}
              </Col>
            ))}
          </Row>
        </Container>
      </div>
    </section>
  );
}
