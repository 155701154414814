import React, { lazy, Suspense, useEffect, useState } from "react";
import { Col, Container, Placeholder, Row } from "react-bootstrap";
import CarMin from "./CarMin";
import css from "./comparison.module.css";
import css1 from "./CarMin.module.css";
import { ReactComponent as Comparar } from "../../../assets/icons/btn/comparar.svg";
import { ReactComponent as Arrow } from "../../../assets/icons/arrow/seta_dir.svg";
import useMediaQuery from "../../hooks/useMediaQuery ";
import ComparisonAdvance from "./ComparisonAdvance";
import { isElementInViewport } from "../../helpers/tratarInfo";
import { ReactComponent as Close } from "../../../assets/icons/modal/fechar.svg";
import IsFicha from "../../hooks/IsFicha";

const ModalComparison = lazy(() => import("./ModalComparison"));

export default function CarComparison({
  selectedItems,
  removeFromCompare,
  show,
  setShow,
  infoJson,
  textoModal,
  setTextoModal,
  removeFromCompareAll,
}) {
  const ficha = IsFicha();
  const ismobile = useMediaQuery("(max-width: 991px)");
  const isDesktop = useMediaQuery("(min-width: 992px)");
  const isMobile = useMediaQuery("(max-width: 767px)");
  const [classValue, setClassValue] = useState(true);
  const [showValue, setShowValue] = useState(false);
  const [size, setSize] = useState(0);
  const [isScroll, setIsScroll] = useState(false);
  const [lastScrollTop, setLastScrollTop] = useState(0);
  const [offset, setOffset] = useState(0);
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    //Quando comparamos as viaturas e abre o comparador  e bloqueia o body
    //para não haver scroll na pagina

    showValue
      ? (document.body.style.overflow = "hidden")
      : (document.body.style.overflow = "visible");
  }, [showValue]);

  useEffect(() => {
    //diminui o numero de viaturas que podem ser comparadas
    isDesktop ? setSize(3) : setSize(2);
  }, [isDesktop, size]);

  const handleScroll = () => {
    //funçao  que verifica se a posiçao no scroll e menor que no momento anterior
    const offset = window.scrollY;
    if (offset > lastScrollTop && offset > 20) {
      setIsScroll(true);
    } else {
      setIsScroll(false);
    }
    setLastScrollTop(offset);
  };

  useEffect(() => {
    //verifica quando o menu do top se esconde
    ismobile && window.addEventListener("scroll", handleScroll);
    return () => {
      ismobile && window.removeEventListener("scroll", handleScroll);
    };
  });

  useEffect(() => {
    const onScroll = () => setOffset(window.pageYOffset);
    // clean up code
    window.removeEventListener("scroll", onScroll);
    window.addEventListener("scroll", onScroll, { passive: true });
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  useEffect(() => {
    // verifica se o elemento com o id = "infoTop" esta dentro do ecrã ou não

    isMobile
      ? setIsVisible(true)
      : document.getElementById("infoTop")
      ? setIsVisible(isElementInViewport(document.getElementById("infoTop")))
      : setIsVisible(true);
  }, [offset, isMobile]);

  const renderSwitch = (param) => {
    switch (param) {
      case 1:
        return (
          <>
            <Col xs={showValue ? 6 : 12} md={6} lg={4}>
              <Row>
                <Col
                  xs={12}
                  md={3}
                  className={`${css1.foto} ${
                    showValue ? css1.dBlockImage : css1.dNoneImg
                  }`}
                >
                  <Placeholder
                    xs={12}
                    style={{ left: "0" }}
                    className={`w-100 image-gallery-slides h-100   left-0  position-absolute`}
                  />
                </Col>
                <Col xs={12} md={9} className={`${css1.box} `}>
                  <Col xs={12} className={` ${css1.marca} `}>
                    <Placeholder xs={4} /> <Placeholder xs={6} />
                    <Placeholder xs={7} className={`  titulo-page`} />
                    <Placeholder xs={6} />
                  </Col>
                </Col>
              </Row>
            </Col>
            {isDesktop && (
              <Col xs={showValue ? 6 : 12} md={6} lg={4}>
                <Row>
                  <Col
                    xs={12}
                    md={3}
                    className={`${css1.foto} ${
                      showValue ? css1.dBlockImage : css1.dNoneImg
                    }`}
                  >
                    <Placeholder
                      xs={12}
                      style={{ left: "0" }}
                      className={`w-100 image-gallery-slides h-100   left-0  position-absolute`}
                    />
                  </Col>
                  <Col xs={12} md={9} className={`${css1.box} `}>
                    <Col xs={12} className={` ${css1.marca} `}>
                      <Placeholder xs={4} /> <Placeholder xs={6} />
                      <Placeholder xs={7} className={`  titulo-page`} />
                      <Placeholder xs={6} />
                    </Col>
                  </Col>
                </Row>
              </Col>
            )}
          </>
        );
      case 2:
        return (
          <>
            {isDesktop && (
              <Col xs={showValue ? 6 : 12} md={6} lg={4}>
                <Row>
                  <Col
                    xs={12}
                    md={3}
                    className={`${css1.foto} ${
                      showValue ? css1.dBlockImage : css1.dNoneImg
                    }`}
                  >
                    <Placeholder
                      xs={12}
                      style={{ left: "0" }}
                      className={`w-100 image-gallery-slides h-100   left-0  position-absolute`}
                    />
                  </Col>
                  <Col xs={12} md={9} className={`${css1.box} `}>
                    <Col xs={12} className={` ${css1.marca} `}>
                      <Placeholder xs={4} /> <Placeholder xs={6} />
                      <Placeholder xs={7} className={`  titulo-page`} />
                      <Placeholder xs={6} />
                    </Col>
                  </Col>
                </Row>
              </Col>
            )}
          </>
        );

      default:
        return <></>;
    }
  };

  return (
    <>
      <section
        className={`${showValue ? css.sectionBig : css.section}  ${
          classValue ? css.open : ""
        } listagem_info ${
          ficha
            ? css.navbarScrollCustomComparar
            : isScroll
            ? `${css.navbarScrollCustomComparar}`
            : ""
        } ${isVisible ? "" : css.navbarScrollCustom}`}
      >
        <section className={` ${css.sectionBox} `}>
          <Container fluid="xxxl">
            <Row
              className="position-relative"
              onClick={() => {
                !showValue
                  ? classValue
                    ? setClassValue(false)
                    : setClassValue(true)
                  : setShowValue(false);
              }}
              style={{ cursor: "pointer" }}
            >
              <Col xs={12} className={css.titulo}>
                Comparador de viaturas
              </Col>

              <div className={`${css.arrow}`}>
                <Arrow height="20px" width="40px" className="close" />
              </div>
            </Row>
            <Row>
              <Col
                xs={showValue ? 12 : 8}
                lg={showValue ? 12 : 9}
                xl={showValue ? 11 : 9}
                xxl={9}
              >
                <Row className="m-0">
                  {selectedItems.slice(0, size).map((item, index) => (
                    <CarMin
                      key={index}
                      carro={item}
                      removeFromCompare={removeFromCompare}
                      showValue={showValue}
                    />
                  ))}
                  {renderSwitch(selectedItems.length)}
                </Row>
              </Col>

              {!showValue && (
                <Col
                  xs={4}
                  lg={3}
                  className={`d-flex align-items-center flex-column `}
                >
                  <div
                    className={`${css.btnComparador} btnComparador`}
                    onClick={
                      selectedItems.length > 1
                        ? () => setShowValue(true)
                        : () => {
                            setShow(true);
                            setTextoModal(
                              "Selecione mais uma viatura para poder comparar."
                            );
                          }
                    }
                  >
                    <Comparar stroke="#FFF" style={{ marginRight: "10px" }} />{" "}
                    {ismobile ? (
                      <>Comparar</>
                    ) : (
                      <>Comparar Viaturas ({selectedItems.length})</>
                    )}
                  </div>
                  <div
                    className={`${css1.remove} ${
                      !showValue ? css1.removeP : ""
                    } ms-0 position-relative mt-2`}
                    onClick={() => removeFromCompareAll()}
                  >
                    <Close height="13px" width="13px" className="close me-2" />{" "}
                    Remover tudo
                  </div>
                </Col>
              )}
            </Row>
          </Container>
        </section>

        {showValue && (
          <>
            <ComparisonAdvance selectedItems={selectedItems} size={size} />
          </>
        )}
      </section>
      {showValue && <div className={css.zone}></div>}

      <Suspense fallback={<></>}>
        <ModalComparison show={show} setShow={setShow} infoJson={infoJson}>
          {textoModal}
        </ModalComparison>
      </Suspense>
    </>
  );
}
