import React from "react";

import { ReactComponent as Comercial } from "../../../../../../assets/icons/tipo/comerc.svg";
import { ReactComponent as Berlina } from "../../../../../../assets/icons/tipo/berlina.svg";
import { ReactComponent as Motos } from "../../../../../../assets/icons/tipo/motos.svg";
import { ReactComponent as Classico } from "../../../../../../assets/icons/tipo/class.svg";
import { ReactComponent as Barcos } from "../../../../../../assets/icons/tipo/tipo_barco.svg";

export const listPesquisaAvancado = (
  allListaCarros,
  ArrayComerciais,
  ArrayClassicos,
  allListaMotas,
  allListaBarcos,
  infoJson
) => {
  return [
    {
      exite: allListaCarros.length !== 0 ? true : false,
      eventKey: "automoveis",
      navigate: `${
        infoJson.Layout.TudoJunto
          ? infoJson.Layout.Carros.existe
            ? "/automoveis/#listagem_viaturas"
            : "/viaturas/automoveis"
          : "/#listagem_viaturas"
      }`,

      svg: <Berlina />,
      label: "Automóveis",
      array: allListaCarros,
      tipo: infoJson.Layout.TudoJunto
        ? infoJson.Layout.Carros.existe
          ? "automoveis"
          : "viaturas/automoveis"
        : "automoveis",
      tipoViatura: 1,
    },
    {
      exite: ArrayComerciais.length !== 0 ? true : false,
      eventKey: "comerciais",
      navigate: `${
        infoJson.Layout.TudoJunto
          ? infoJson.Layout.Carros.existe
            ? "/comerciais/#listagem_viaturas"
            : "/viaturas/comerciais"
          : "/#listagem_viaturas"
      }`,
      svg: <Comercial />,
      label: "Comerciais",
      array: ArrayComerciais,
      tipo: infoJson.Layout.TudoJunto
        ? infoJson.Layout.Carros.existe
          ? "comerciais"
          : "viaturas/comerciais"
        : "carros/comerciais/",

      tipoViatura: 1,
    },
    {
      exite: ArrayClassicos.length !== 0 ? true : false,
      eventKey: "classicos",
      navigate: `${
        infoJson.Layout.TudoJunto
          ? infoJson.Layout.Carros.existe
            ? "/classicos/#listagem_viaturas"
            : "/viaturas/classicos"
          : "/#listagem_viaturas"
      }`,
      svg: <Classico />,
      label: "Clássicos",
      array: ArrayClassicos,
      tipo: infoJson.Layout.TudoJunto
        ? infoJson.Layout.Carros.existe
          ? "classicos"
          : "viaturas/classicos"
        : "carros/classicos/",

      tipoViatura: 1,
    },
    {
      exite: allListaMotas.length !== 0 ? true : false,
      eventKey: "motas",
      navigate: `${
        infoJson.Layout.TudoJunto
          ? infoJson.Layout.Carros.existe
            ? "/motas/#listagem_viaturas"
            : "/viaturas/motas"
          : "/motas/usadas/"
      }`,
      svg: <Motos />,
      label: "Motas",
      array: allListaMotas,
      tipo: infoJson.Layout.TudoJunto
        ? infoJson.Layout.Carros.existe
          ? "motas"
          : "viaturas/motas"
        : "motas/usadas/",

      tipoViatura: 2,
    },
    {
      exite: allListaBarcos.length !== 0 ? true : false,
      eventKey: "barcos",
      navigate: `${
        infoJson.Layout.TudoJunto
          ? infoJson.Layout.Carros.existe
            ? "/barcos/#listagem_viaturas"
            : "/viaturas/barcos"
          : "/barcos/usadas/"
      }`,
      svg: <Barcos stroke="none" />,
      label: "Barcos",
      array: allListaBarcos,
      tipo: infoJson.Layout.TudoJunto
        ? infoJson.Layout.Carros.existe
          ? "barcos"
          : "viaturas/barcos"
        : "barcos/usados/",

      tipoViatura: 3,
    },
  ];
};
