import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { slice } from "lodash";
import { Autoplay, Pagination, Navigation } from "swiper";
import css from "../carrousel.module.css";
import { ReactComponent as Arrow } from "../../../../assets/icons/arrow/Slider.svg";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import useMediaQuery from "../../../hooks/useMediaQuery ";
import CardBullet from "../utimasEntradas/CardBullet";
import { formateDate } from "../../../helpers/tratarInfoCarros";
import CardRecentes from "../utimasEntradas/CardRecentes";

export default function CarrouselVendidos({ allLista, infoJson }) {
  const ismobile = useMediaQuery("(max-width: 574px)");
  const [vendidos, setVendidos] = useState([]);

  useEffect(() => {
    const vendidos = slice(
      allLista
        .filter((item) => item.Vendido === true)
        .slice()
        .sort(
          (a, b) =>
            formateDate(b.UltimaAlteracao).getTime() -
            formateDate(a.UltimaAlteracao).getTime()
        ),
      0,
      infoJson.Layout.Vendidos.top
    );
    setVendidos(vendidos);
  }, [allLista, infoJson.Layout.Vendidos.top]);

  const responsive = {
    0: {
      slidesPerView: 2.3,
    },
    375: {
      slidesPerView: 3.3,
    },
    574: {
      slidesPerView: 4,
    },
    767: {
      slidesPerView: 5,
    },
    991: {
      slidesPerView: 5,
    },
    1199: {
      slidesPerView: 6,
    },
    1399: {
      slidesPerView: 7,
    },
    1600: {
      slidesPerView: 8,
    },
  };

  const responsiveR = {
    0: {
      slidesPerView: 1,
    },
    375: {
      slidesPerView: 1.3,
    },
    574: {
      slidesPerView: 2,
    },
    767: {
      slidesPerView: 3,
    },
    991: {
      slidesPerView: 4,
    },
    1199: {
      slidesPerView: 5,
    },
  };

  const navigationVendidosPrevRef = React.useRef(null);
  const navigationVendidosNextRef = React.useRef(null);

  const selectCard = (carro, infoJson) => {
    switch (infoJson.Layout.Vendidos.tipo) {
      case 3:
        return <CardRecentes data={carro} />;
      default:
        return <CardBullet data={carro} tipo={infoJson.Layout.Vendidos.tipo} />;
    }
  };

  return (
    <>
      {" "}
      <Swiper
        className={"recentes-slider"}
        breakpoints={
          infoJson.Layout.Vendidos.tipo === 3 ? responsiveR : responsive
        }
        // autoplay={ismobile ? false : true}
        pagination={false}
        navigation={
          ismobile
            ? false
            : {
                prevEl: navigationVendidosPrevRef.current,
                nextEl: navigationVendidosNextRef.current,
                disabledClass: css.buttonDisable,
                lockClass: css.buttonLock,
              }
        }
        onBeforeInit={(swiper) => {
          swiper.params.navigation.prevEl = navigationVendidosPrevRef.current;
          swiper.params.navigation.nextEl = navigationVendidosNextRef.current;
        }}
        effect={"slide"}
        centerInsufficientSlides={true}
        centeredSlides={false}
        loop={false}
        speed={500}
        modules={[Autoplay, Pagination, Navigation]}
      >
        {vendidos.map((carro) => {
          return (
            <SwiperSlide key={carro.CodViatura}>
              {selectCard(carro, infoJson)}
            </SwiperSlide>
          );
        })}
              {ismobile ? (
        <></>
      ) : (
        <>
          <div
            className={`${css.buttonPrev} ${
              infoJson.Layout.Vendidos.tipo !== 3 ? css.buttonPrevR : ""
            }`}
            ref={navigationVendidosPrevRef}
          >
            <Arrow
              height="60px"
              style={{ transform: `rotate(180deg)` }}
              stroke={infoJson.Cores.ArrowSlider}
            />
          </div>
          <div
            className={`${css.buttonNext} ${
              infoJson.Layout.Vendidos.tipo !== 3 ? css.buttonNextR : ""
            }`}
            ref={navigationVendidosNextRef}
          >
            <Arrow height="60px" stroke={infoJson.Cores.ArrowSlider} />
          </div>
        </>
      )}
      </Swiper>

    </>
  );
}
